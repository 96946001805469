//import logo from './logo.svg';
import './App.css';
import { RootBar } from './pages/Root';

function App() {
  return (
    <RootBar />
  );
}

export default App;
