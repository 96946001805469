import "./components.css"

export function NavigateTicketPage({onClick}){
    return(
            <button className="NavigateTicketPage" onClick={onClick}>Đặt vé ngay</button>
    )
}

export function NavigateHomeandLogin({onClick, name}){
    return(
            <button className="NavigateHome" onClick={onClick}>{name}</button>
    )
}

export function HugeButton({onClick}){
    return(
            <button className="HugeButton" onClick={onClick}>Đặt vé ngay</button>
    )
}

export function IntroButton({onClick}){
    return(
        <button className="IntroButton" onClick={onClick}>Giới thiệu</button>
    )
}
