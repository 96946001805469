import { useState } from "react";
import { link } from "./link";
import axios from "axios";
import { SearchCard } from "../components/Cards";

export function SearchPage(){
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const onSearch = () => {
        setLoading(true);
        axios.post(link.baseURL + '/app/customer/basket', { searchkey: document.getElementById('searchkey').value }, {
            headers : {
                'Content-Type' : 'application/json'
            }
        }).then((res) => {
            console.log(res.data)
            setResult(res.data.reverse());
            setLoading(false);
        }).catch(console.error);
    }
    if(loading) return(<div>Đang tải...</div>)
    return(
        <>
        <div className="m-3 border p-3">
            <label for="searchkey" className="form-label">Nhập thông tin để tìm kiếm lịch sử đặt vé của bạn</label>
            <input type="text" className="form-control mb-1" id="searchkey" placeholder="Nhập mã đặt vé hoặc số điện thoại, số chứng minh nhân dân mà bạn đã dùng để đặt vé"/>
            <button className="btn btn-success" onClick={onSearch}>Gửi thông tin</button>
        </div>
        
        
        <div className="container">
            <h4>Lịch sử đặt vé</h4>
        {
            result && result.length != 0 ? result.map((value, index) => {
                return(<SearchCard value={value} />);
            }) : (<div>Không tìm thấy kết quả</div>)
        }
        </div>
        </>
    );
}