import './components.css'

export function TicketCard({value, image, onAddClick, onExcludeClick, onPlus, onMinus}){
    //const value = data.data();
    return (
        <div className={"TicketCard" + (value.num != 0 ? " Picked" : "")}>
            {/* Image */}
        <div className='ImageContainer'>
            <img src={'./ticket_4271.png'} alt='not found'/>
        </div>
        <h6>{value.name}</h6>
        <h6>Giá: {value.price}</h6>
        {
            value.num == 0 ? (<button className="adding" onClick={onAddClick}>Thêm vào giỏ hàng</button>) : (
                <>
                <button className='changingNum' onClick={onPlus}>+</button>
                <div>Số lượng: {value.num}</div>
                <button className='changingNum' onClick={onMinus}>-</button>
                <button className='ExcludeButton' onClick={onExcludeClick}>Bỏ khỏi giỏ hàng</button>
                </>
            )
        }
        </div>
    )
}

export function BasketCard({value, onExcludeClick}){
    return (
        <div className='BasketCard'>
            <h6>{value.name}</h6>
            <h6>Giá: {value.price}</h6>
            <h6 style={{color: 'black'}}>Số lượng: {value.num}</h6>
            <h6>Ngày đi trên vé: {value.date_on_ticket}</h6>
            <button className='ExcludeButton' onClick={onExcludeClick}>Bỏ khỏi giỏ hàng</button>
        </div>
    )
}

export function SearchCard({value}){
    return (
        <div className='card card-body'>
            <h6>Họ và tên: {value.name}</h6>
            <h6>Số điện thoại: {value.phone}</h6>
            <h6>CMND: {value.uid}</h6>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Vé</th>
                        <th>Số lượng</th>
                    </tr>
                </thead>
                <tbody>
                {
                    value.ticket.map(element => {
                        return(
                            <tr>
                                <td>{element.name}</td>
                                <td>{element.num}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
            
            <h6>Ngày đặt vé: {value.date_book}</h6>
            <h6>Giá: {value.money}</h6>
            <h6 style={{color: 'black'}}>Mã booking: {value.booking_code}</h6>
            <h6 style={{color: 'black'}}>Ngày đi trên vé: {value.date_on_ticket}</h6>
        </div>
    )
}

export function HistoryCard({value}){
    let d = value.date_on_ticket.toDate();
    return (
        <button  className='HistoryCard'>
            {
                value.ticket.map(element => {
                    return(
                        <>
                        <h6>{element.name} - Số lượng: {element.num}</h6>
                        </>
                    )
                })
            }
            <h6>Ngày đặt vé: {Date(value.date_book)}</h6>
            <h6>Giá: {value.money}</h6>
            <h6 style={{color: 'black'}}>Mã booking: {value.booking_code}</h6>
            <h6 style={{color: 'black'}}>Ngày đi trên vé: {"" + d.getDate() + " - " + d.getMonth() + " - " + d.getFullYear()}</h6>
        </button>
    )
}