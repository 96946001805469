import { HugeButton, IntroButton } from "../components/Buttons"
import { Link } from "react-router-dom"
export function Home() {
    let toggledColor = true;
    const data = [
        'Hướng dẫn mua vé',
        'Trải nghiệm tuyệt vời tại những khu vui chơi khác biệt',
        'Trải nghiệm khác lạ blah blah'
    ]
    const intro = [0, 1, 2];
    return (
        <>
            <div className="banner">
                <div>
                    <h1>Trải nghiệm du lịch khác biệt</h1>
                </div>
                <div>
                    <Link to="/ticket"><HugeButton /></Link>
                </div>
            </div>
            {data.map((item, index) => {
                toggledColor = !toggledColor;
                return(
                    <div key={index} className="bannerIntro" style={toggledColor ? {backgroundColor: '#DDFFBC'} : {backgroundColor: '#FEFFDE'}}>
                        <h3>{item}</h3>
                        <Link to={'/intro/' + intro[index]}><IntroButton /></Link>
                    </div>
                )
            })}
            <div className="footer">
                <h2>Thông tin liên lạc hỗ trợ khi gặp sự cố hoặc trao đổi trực tiếp</h2>
                <h4>Zalo: 0338289786</h4>
                <h4>Gmail: thichngao@gmail.com</h4>
            </div>
        </>
    )
}