import { useParams } from "react-router-dom";
import { BasketCard } from "../components/Cards"
import './style.css'
import { useEffect } from "react";

export function Basket(){
    const {id} = useParams();
    let sum = 0;
    const ticket = JSON.parse(sessionStorage.getItem("ticket"));
    const basket = JSON.parse(sessionStorage.getItem("basket"));
    return (
        <>
        <div className="Basket">
            {
                basket ? basket.map((item, index) => {
                    sum += ticket[item.id]["price"] * item.num;
                    return (
                        <BasketCard key={index} name={ticket[item.id]["name"]} price={ticket[item.id]["price"]} num={item.num} date={item.date} onExcludeClick={() => {
                            let content;
                            if(item.num > 0){
                                content = "Success!";
                                item.num--;
                            }
                            else{
                                content = "Bạn đã bỏ hêt vé này ra khỏi giỏ";
                            }
                            
                            sessionStorage.setItem("basket", JSON.stringify(basket));
                            const container = document.getElementById("overlaycontainer");
                            const newitem = document.createElement("div");
                            newitem.innerHTML = content;
                            newitem.className = "overlay";
                            container.appendChild(newitem);
                        }}/>
                    )
                }) : (
                    <div>Bạn chưa bỏ vé vào giỏ hàng</div>
                )
            }
        </div>
        <div className="overlaycontainer" id="overlaycontainer">

        </div>
        <div className="SumMoney">
            <h3>Tổng số tiền: <span>{sum}</span></h3>
            <button className="Payment">Thanh toán</button>
        </div>
        </>
        
    )
}