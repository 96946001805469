import { useParams } from "react-router-dom";
import { HistoryCard } from "../components/Cards";
import { useCollectionData } from"react-firebase-hooks/firestore";
import { getFirestore, query, where, limit, collection } from "firebase/firestore";
import "./style.css"
import { useState } from "react";
import Lottie from "lottie-react";



export function Profile(){
    // const {id} = useParams();
    // const history = JSON.parse(sessionStorage.getItem("history"));
    // const ticket = JSON.parse(sessionStorage.getItem("ticket"));
    const firestore = getFirestore();
    const q = query(collection(firestore, "basket_database"), where("uid", "==", sessionStorage.getItem("uid")), where("state", "==", "Đã trả tiền"));
    const [history] = useCollectionData(q, {idField: "id"});
    // const [loading, setLoading] = useState(true);
    // console.log(history);
    // if(loading) return (
    //     <Lottie source={require('../components/assets/loading.json')} autoPlay loop />
    // );
    if(!history){
        return (<Lottie autoPlay loop animationData={require("../components/assets/loading.json")} />)
    }
    return (
        <>
        <div className="History">
            {
                history.length == 0 ? (<div>
                    Bạn chưa đăng ký gì cả
                </div>) : history.map((item, index) => (
                    <HistoryCard value={item} />
                ))
            }
        </div>
        </>
    )
}