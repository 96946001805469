import { useParams } from "react-router-dom"

export function Introduction(){
    const {id} = useParams();
    if(id == 0){
        return(
            <>
            <h3>Các bước để có thể mua vé:</h3>
            <ul>
                <li>1. Ấn vào nút "Đăng nhập" ở trên góc phải trên cùng và đăng nhập bằng tài khoản gmail của bạn</li>
                <li>2. Ấn vào nút "Đặt vé ngay" để chuyển đến trang đặt vé</li>
                <li>3. Nhập vào ngày mà bạn muốn sử dụng vé trước, sau đó chọn vé và số lượng vé bạn cần mua trong danh sách vé. Tất cả những vé được chọn sẽ được thanh toán cùng một lúc</li>
                <li>4. Bấm vào nút "Thanh toán" để được chuyển tới trang thanh toán sau vài giây</li>
                <li>5. Sau khi thanh toán thành công, bạn sẽ được chuyển lại về trang chủ. Bấm vào biểu tượng cá nhân ở góc trên bên phải để có thể xem lịch sử mua vé. Ở đây bạn có thể tìm thấy mã booking để trình trước quầy kiểm soát</li>
            </ul>
            Sử dụng bất kỳ thẻ test nào trên trang <a href='https://sandbox.vnpayment.vn/apis/vnpay-demo/ '>https://sandbox.vnpayment.vn/apis/vnpay-demo/</a>.
            </>
        );
    }
    return(
        <div>
            <h3>Điểm nổi bật</h3>
        <p>Bạn có thể mua vé xe Bus từ Đà Nẵng đi Núi Thần Tài </p>
        <p>Khu Du Lịch Suối Khoáng Nóng Núi Thần Tài, một điểm tham quan dành cho những người yêu thiên nhiên ở Đà Nẵng!</p>
        <p>Thư giãn toàn thân trong dòng suối mát và vui chơi thật vui với nhiều hoạt động dưới nước hấp dẫn</p>
        <p>Giải độc và làm mịn da trong khu vực tắm bùn với phương pháp trị liệu thủy sinh độc đáo</p>
        <p>Tham quan Tháp Onsen để có trải nghiệm tắm kiểu Nhật truyền thống</p>
        <p>Khu du lịch Núi Thần Tài còn mang đến niềm vui và cảm giác hồi hộp cho những ai đam mê vận động</p>
        <p>Xem phim 9D đến 12D miễn phí tại rạp chiếu phim và thưởng thức dịch vụ ẩm thực phong phú</p>
        <p>Vui lòng lưu ý rằng Bạn sẽ nhận được voucher trong phòng 30 phút tính từ thời điểm mua</p>
        </div>
    )
}