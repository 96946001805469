import { useEffect, useState } from 'react';
import { SearchCard, TicketCard } from '../components/Cards';
import { link } from './link';
import { redirect, useAsyncError, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import './style.css';
import image from './qr.jpg';

import axios from 'axios';

export function PickTicket(){
    const navigate = useNavigate();
    const [cart, setCart] = useState([]);
    ///////////// Ticket API
    useEffect(() => {
        axios.get(link.baseURL + '/app/ticket').then((res) => {
            console.log(res.data);
            setCart(res.data);
        }).catch(console.error);
    }, []);

    const [validDate, setValidDate] = useState(false);
    const [paid, setPaid] = useState(false);
    const [bill, setBill] = useState(null);
    const [loading, setLoading] = useState(false);
    let sum = 0;
    const popup = (str) => {
        const container = document.getElementById("overlaycontainer");
        const newitem = document.createElement("div");
        newitem.innerHTML = str;
        newitem.className = "overlay";
        container.appendChild(newitem);
    }
    const onFinish = () => {
        let input = document.getElementById('name').value;
        if(input == undefined || input == ""){
            popup("Chưa nhập tên");
            return;
        }
        input = document.getElementById('phone').value;
        if(input == undefined || input == ""){
            popup("Chưa nhập số điện thoại");
            return;
        }
        input = document.getElementById('uid').value;
        if(input == undefined || input == ""){
            popup("Chưa nhập số chứng minh nhân dân");
            return;
        }
        let d = new Date(document.getElementById('date_on_ticket').value);
        let now = new Date();
        if(d == "Invalid Date"){
            popup("Ngày không hợp lệ");
            return;
        }
        if(d.valueOf() <= now.valueOf()) {
            popup("Bạn phải chọn ngày sau ngày hôm nay");
            return;
        }
        let nextCart = cart.slice();
        setValidDate(true);
        setCart(nextCart);
    }
    const onAddClick = (i) => {
        let nextCart = cart.slice();
        nextCart[i].num = 1;
        setCart(nextCart);
        popup("Thành công!");
    }
    const onExcludeClick = (i) => {
        let nextCart = cart.slice();
        nextCart[i].num = 0;
        setCart(nextCart);
        popup("Thành công!");
    }
    const onPlusClick = (i) => {
        let nextCart = cart.slice();
        nextCart[i].num++;
        setCart(nextCart);
        popup("Thành công!");
    }
    const onMinusClick = (i) => {
        let nextCart = cart.slice();
        if(nextCart[i].num > 1) {
            nextCart[i].num--;
            setCart(nextCart);
            popup("Thành công!");
        }
        else{
            popup("Bạn không thể giảm nhỏ hơn 1");
        }
    }
    const onPay = () => {
        try{
            let rand = Math.floor(Math.random() * 10000);
            let d = new Date();
            let date_on_ticket = new Date(document.getElementById('date_on_ticket').value)
            let collection_id = "" + d.getDate() + "-" + d.getMonth() + "-" + d.getFullYear() + "_" + rand + d.getHours() + d.getMinutes() + d.getSeconds();
            let o = {
                paid: false,
                date_on_ticket: "" + date_on_ticket.getDate() + "-" + date_on_ticket.getMonth() + "-" + date_on_ticket.getFullYear(),
                name: document.getElementById('name').value,
                phone: document.getElementById('phone').value,
                uid: document.getElementById('uid').value,
                money: sum,
                ticket: [],
                date_book: "" + d.getDate() + "-" + d.getMonth() + "-" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds(),
                booking_code: collection_id
            }
            
            cart.forEach((i) => {
                if(i.num != 0){
                    let temp = {};
                    temp.ticket_id = i.ticket_id;
                    temp.num = i.num;
                    temp.name = i.name;
                    o.ticket.push(temp);
                }
            })
            axios.post(link.baseURL + '/app/customer/buy', o, {
                headers:{
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                popup('Thành công!');
                setBill(o);
                setPaid(true);
            }).catch(console.error);
        }
        catch(err){
            console.error(err);
            popup("Có lỗi xảy ra");
        }
    }
    if(loading) return (<Lottie autoPlay loop animationData={require("../components/assets/loading.json")} />)
    return (
        <div>
        <div className='card p-3 m-3'>
            <h3>Các bước để có thể mua vé:</h3>
            <ul>
                <li>1. Nhập đầy đủ thông tin cá nhân cần thiết vào ô bên dưới.</li>
                <li>2. Chọn ngày sử dụng vé. Ấn nút hoàn thành</li>
                <li>3. Chọn tất cả vé bạn muốn sử dụng trong ngày ở dưới cùng và điều chỉnh số lượng</li>
                <li>4. Bấm vào nút "Thanh toán" để gửi yêu cầu đặt vé. Sau đó thanh toán qua mã QR code hiện ra và ghi nội dung chuyển tiền là tên, số điện thoại của bạn</li>
            </ul>
        </div>
        <div className='daypicker'>
            <h3>Điền đầy đủ thông tin cá nhân. Hãy kiểm tra thông tin thật chính xác: </h3>
            <div className='d-flex gap-3 flex-wrap'>
                <div class="mb-3">
                    <label for="name" className="form-label">Họ và tên</label>
                    <input className='form-control' disabled={validDate} id='name' type='text' name='name' placeholder='Họ và tên' />
                </div>
                <div class="mb-3">
                    <label for="phone" className="form-label">Số điện thoại</label>
                    <input className='form-control' disabled={validDate} id='phone' type='text' name='phone' placeholder='Số điện thoại' />
                </div>
                <div class="mb-3">
                    <label for="uid" className="form-label">Số chứng minh nhân dân</label>
                    <input className='form-control' disabled={validDate} id='uid' type='text' name='uid' placeholder='Số chứng minh nhân dân' />
                </div>
                <div class="mb-3">
                    <label for="date_on_ticket" className="form-label">Ngày sử dụng vé</label>
                    <input disabled={validDate} id='date_on_ticket' type='date' className='form-control'/>
                </div>
            </div>
            {
                validDate ? <button className='btn bg-success-subtle' onClick={() => {setValidDate(false)}}>Quay lại</button>
                :
                <button className='btn btn-success' onClick={onFinish}>Hoàn thành</button>
            }
        </div>
        {
            validDate && (<>
            <div className="Shop">
                {
                    cart && cart.length != 0 ? cart.map((item, index) => {
                        if(item.num != 0){
                            sum += item.price * item.num;
                            return (
                                <TicketCard key={index} value={item} onAddClick={() => onAddClick(index)}
                                onExcludeClick={() => onExcludeClick(index)}
                                onMinus={() => onMinusClick(index)}
                                onPlus={() => onPlusClick(index)}/>
                            )
                        }
                    }) : (
                        <div>Hiện tại không có vé trên hệ thống</div>
                    )
                }
            </div>
            <div className="Shop">
                {
                    cart && cart.length != 0 ? cart.map((item, index) => {
                        if(item.num == 0){
                            //sum += item.price * item.num;
                            return (
                                <TicketCard key={index} value={item} onAddClick={() => onAddClick(index)}
                                onExcludeClick={() => onExcludeClick(index)}
                                onMinus={() => onMinusClick(index)}
                                onPlus={() => onPlusClick(index)}/>
                            )
                        }
                    }) : (
                        <div>Bạn cần đăng nhập trước mới có thể đặt vé</div>
                    )
                }
            </div>
            <div className="SumMoney border border-top border-2">
                <h5>Tổng số tiền: <span>{sum}</span></h5>
                {
                    sum != 0 && (
                        <>
                        <button className="btn btn-success" onClick={onPay}>Gửi đăng ký</button>
                        </>
                    )
                }
            </div>
            </>)
        }
        <div className='overlaycontainer' id='overlaycontainer'>

        </div>
        {
            paid && (
                <div className='overlayBackground'>
                    <div className='overlayNotification'>
                        Hóa đơn đăng ký của bạn. Vui lòng chụp ảnh lại:
                        {
                            bill && <SearchCard value={bill} />
                        }
                        Bạn đã đăng ký xong. Vui lòng thanh toán số tiền {sum} đồng theo mã QR bên dưới và ghi tên cùng với số điện thoại của bạn vào nội dung chuyển tiền.
                        <div>
                            <img src={image} alt='Not found' width={300}/>
                        </div>
                        <button className='btn btn-success' onClick={() => {
                            setPaid(false);
                            navigate("/");
                        }}>Trở về trang chủ</button>
                    </div>
                </div>
            )
        }
        </div>
    )
}