import { Home } from "./Home"
import { PickTicket } from "./PickTicket"
import { NavigateHomeandLogin, NavigateTicketPage } from "../components/Buttons"
import {Route, Routes, Link, Outlet} from "react-router-dom"
import "./style.css"
import { useState, useEffect } from "react"
import { Introduction } from "./Introduction"
import { Basket } from "./Basket"
import { Profile } from "./Profile"
import { SearchPage } from "./SearchPage"
import { HiOutlineBell, HiOutlineShoppingCart, HiUserCircle, HiOutlineLogout, HiOutlineChat } from "react-icons/hi"

//import { useAuthState } from "react-firebase-hooks/auth"
import { ChatRoom } from "./Chatroom"


export function RootBar(){
    return(
        <Routes>
            <Route path="/" element={<RootLayout />}>
                <Route index element={<Home />} />
                <Route path="ticket" element={<PickTicket />} />
                <Route path="intro/:id" element={<Introduction />} />
                <Route path="basket" element={<Basket />} />
                <Route path="profile" element={<Profile />} />
                <Route path="chatroom" element={<ChatRoom />} />
                <Route path="search" element={<SearchPage />} />
            </Route>
        </Routes>
    )
}


export function RootLayout(){
    const [loading, setLoading] = useState(false);
    if(loading){
        return(<div>Đang tải...</div>);
    }
    return(
        <>
        <div className="navbar">
            <Link to="/"><NavigateHomeandLogin name={"Trang chủ"}/></Link>
            <Link to="/ticket"><NavigateHomeandLogin name={"Đặt vé"} /></Link>
            <Link to="/search"><NavigateHomeandLogin name={"Tìm kiếm vé đã mua"}/></Link>
        </div>
        <Outlet />
        </>
    )
}